import React, { useEffect, useState } from 'react';

import { Route, Navigate, Routes, useLocation } from 'react-router-dom';
import { CrearCrompra } from '../pages/CrearCrompra';
import { MainMenu } from '../pages/MainMenu';
import { EditarCompras } from '../pages/EditarCompras';
import { VerCompras } from '../pages/VerCompras';
import { PdfCompra } from '../components/global/PdfCompra';
import { CompraViwerPDF } from '../components/global/CompraViwerPDF';
import { Login } from '../pages/Login';
import axios from 'axios';

import PrivateRoute from './PrivateRoute';
import { Header } from '../components/global/Header';
import { VerFirmadas } from '../pages/VerFirmadas';
import { ComprasAica } from '../components/aica/ComprasAica';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { DocumentosFirmas } from '../pages/DocumentosFirmas';
import { VerEnviadas } from '../pages/VerEnviadas';


const Router = () => {
	//global
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const theme = createTheme({
		palette: {
			primary: {
				main: '#ff8d22',
			},
			secondary: {
				main: '#e84e1b',
			},
		},

	});
	return (
		<ThemeProvider theme={theme}>

			<div className='image-bg'>
				<Routes>
					<Route element={<PrivateRoute />}>
						<Route path="/" element={<MainMenu />}></Route>
						<Route path="/crear-compra" element={<CrearCrompra />}></Route>
						<Route path="/ver-compras" element={<VerCompras />}></Route>
						<Route path="/editar-compra" element={<EditarCompras />}></Route>
						<Route path="/ver-compra/:id" element={<EditarCompras />}></Route>
						{/* <Route path="/ver-firmadas" element={<VerFirmadas />}></Route> */}
						<Route path="/ver-enviadas" element={<VerEnviadas />}></Route>
						<Route path="/aica" element={<ComprasAica />}></Route>
						<Route path="/pdf-compra/:id" element={<PdfCompra />}></Route>
						<Route path="/pdf-preview/:id" element={<CompraViwerPDF />}></Route>
						{/* docuten */}
						<Route path="/listado-documentos" element={<DocumentosFirmas />}></Route>
					</Route>
					<Route path="/login" element={<Login username={username} setUsername={setUsername} password={password} setPassword={setPassword} />}></Route>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</div>
		</ThemeProvider>

	)
}
export default Router