import React, { useEffect, useState } from 'react'
import { getListaDocumentos, getopcinesCompras } from '../services/apis';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../utils/cookies';
import { Grid } from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Header } from '../components/global/Header';
import { CompraEnviadaCard } from './CompraEnviadaCard';

export const VerEnviadas = () => {
    //util
    const [comprasEnviadas, setComprasEnviadas] = useState([]);
    const wsUrl = "compras.clasol.com";
    const Navigate = useNavigate();
    const token = getCookie('PHPSESSID');

    // Objeto con colores según estado
    const statusColors = {
        REJECTED: "#ffcccc",  // rojo claro
        EXPIRED: "#f2f2f2",  // gris claro
        PENDING: "#fff8c6",  // amarillo claro
        CANCELED: "#ffd8d8",  // rosa claro
        VALIDATION_PENDING: "#d8ebff", // azul muy claro
        INVALIDATE: "#f5d8ff", // lila claro
        ENDED: "#d8ffd8"   // verde claro
    };

    const cargarOpcionesFirmadas = async () => {
        try {
            const response = await getopcinesCompras();
            console.log("Opciones del menú: ", response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const cargarComprasEnviadas = async () => {
        try {
            const response = await getListaDocumentos();
            setComprasEnviadas(response.data.documents);
            console.log("Documentos: ", response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        cargarComprasEnviadas();
        cargarOpcionesFirmadas();
    }, []);

    return (
        <div>
            <Header />
            <Grid container xs={12} justifyContent={'center'}>
                <Grid container xs={12} justifyContent={'start'}>
                    <div className='return-button' onClick={() => Navigate("/")}>
                        <KeyboardReturnIcon />
                        &nbsp;Volver
                    </div>
                </Grid>
                <Grid container xs={12} gap={1} justifyContent={'center'} direction={'row'}>
                    {comprasEnviadas.map((compra, index) => {
                        // Determinamos el color de fondo según el status
                        const bgColor = statusColors[compra.status_code] || '#ffffff';
                        return (
                            <Grid
                                container
                                direction={'row'}
                                justifyContent={'center'}
                                xs={11}
                                lg={2}
                                key={index}
                                style={{ backgroundColor: bgColor }} // <-- aquí aplicamos el color
                            >
                                <CompraEnviadaCard compra={compra} />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </div>
    );
};
