import React, { useEffect, useState } from 'react';
import { Header } from '../components/global/Header';

import TextField from '@mui/material/TextField';
import { Autocomplete, Checkbox, Grid, Input, MenuItem, Select } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import dayjs from 'dayjs';
import axios from 'axios';
import { AltaProveedor } from '../components/AltaProveedor';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { ProveedorCard } from '../components/compras/ProveedorCard';


export const CrearCrompra = () => {

    //global
    const Navigate = useNavigate();
    const wsUrl = "compras.clasol.com";


    //util
    const [representantes, setRepresentantes] = useState([]);
    const [representante, setRepresentante] = useState([]);
    const [variedades, setVariedades] = useState([]);
    const [variedad, setVariedad] = useState("");
    const [opVariedades, setOpVariedades] = useState([]);

    const [modalidad, setModalidad] = useState([]);
    const [ejercicio, setEjercicio] = useState([]);
    const [ejercicios, setEjercicios] = useState([]);

    const [gurposPortes, setGruposPortes] = useState([]);
    const [opGrupoPortes, setopGrupoPortes] = useState([]);
    const [grupoPorte, setGrupoPorte] = useState("");

    const [proveedores, setProveedores] = useState([]);
    const [proveedor, setProveedor] = useState("");
    const [opProveedores, setOpProveedores] = useState([]);
    const [arrProveeCompra, setArrProveeCompra] = useState([]);
    const [tmpProve, setTmpProve] = useState("");

    const [proveForm, setProveFrom] = useState(false);
    const [anticipado, setAnticipado] = useState(false);
    const [multiProveedor, setMultiProveedor] = useState(false);
    const [dissSldiers, setDissSliders] = useState(false);
    const [sinComision, setSinComision] = useState(false);
    const [preview, setPreview] = useState(false);

    //compra obj setup
    const [compra, setCompra] = useState({
        'Ejercicio': null,
        'CodigoCompra': null,
        'Fecha': new Date().toISOString().split('T')[0],
        'Proveedor': null,
        'Referencia': null,
        'Comprador': "23",
        'Fecha1Recoleccion': null,
        'Representante': null,
        'Variedad': null,
        'Modalidad': null,
        'Cantidad': null,
        'Precio': null,
        'Comisiones': null,
        'Observaciones': null,
        'Anticipo': null,
        'PrecioRecolectado': null,
        'grupoPortes': null,
        'ivaPrecio': false,
    });

    useEffect(() => {
        firstData();
        if (localStorage.getItem('code') != '0000') {
            compra.Representante = localStorage.getItem('code');
        }
    }, []);

    const handleChange = (event) => {
        if (event.target.name === "Precio") {
            if (event.target.value > 0) {
                if ((event.target.value > 2 && compra.Modalidad !== '02') || event.target.value > 100000) {
                    Swal.fire({
                        title: 'Estas seguro que el precio es ' + event.target.value + '€ por KG?',
                        text: "Este precio esta fuera del rango comun de precios!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#ff8d22',
                        cancelButtonColor: '#ff8d22',
                        fontSize: "20px",
                        fontWeigth: "600",
                        confirmButtonText: 'Sí, estoy seguro!',
                        cancelButtonText: 'No, no estoy seguro'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: 'Guardado!',
                                text: 'Se ha guardado correctamente',
                                icon: 'success',
                                confirmButtonColor: '#ff8d22'
                            }
                            )
                        } else {
                            const nuevaCompra = { ...compra, [event.target.name]: 0 };
                            setCompra(nuevaCompra);
                        }
                    })
                }
                const inputValue = parseFloat(event.target.value);
                // Limita a 6 decimales usando toFixed y luego convierte de nuevo a número
                const roundedValue = parseFloat(inputValue.toFixed(5));
                const nuevaCompra = { ...compra, [event.target.name]: roundedValue };
                setCompra(nuevaCompra);
            } else {
                const nuevaCompra = { ...compra, [event.target.name]: event.target.value };
                setCompra(nuevaCompra);
            }

        } else if (event.target.name === "Cantidad") {
            const inputValue = parseFloat(event.target.value);
            // Limita a 2 decimales usando toFixed y luego convierte de nuevo a número
            const roundedValue = parseFloat(inputValue.toFixed(0));
            const nuevaCompra = { ...compra, [event.target.name]: roundedValue };
            setCompra(nuevaCompra);
        } else if (event.target.name === "Anticipo") {

            const inputValue = parseFloat(event.target.value);
            // Limita a 2 decimales usando toFixed y luego convierte de nuevo a número
            const roundedValue = parseFloat(inputValue.toFixed(0));
            const nuevaCompra = { ...compra, [event.target.name]: roundedValue };
            setCompra(nuevaCompra);
            if (roundedValue > 100000) {
                Swal.fire({
                    title: 'Estas seguro que el Anticipo es ' + roundedValue + '€ ?',
                    text: "Este precio esta fuera del rango habitual de anticipo!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ff8d22',
                    cancelButtonColor: '#ff8d22',
                    fontSize: "20px",
                    fontWeigth: "600",
                    confirmButtonText: 'Sí, estoy seguro!',
                    cancelButtonText: 'No, no estoy seguro'
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: 'Guardado!',
                            text: 'Se ha guardado correctamente',
                            icon: 'success',
                            confirmButtonColor: '#ff8d22'
                        }
                        )
                    } else {
                        const nuevaCompra = { ...compra, [event.target.name]: 0 };
                        setCompra(nuevaCompra);
                    }
                })
            }
        } else if (event.target.name === "Comisiones") {
            if (event.target.value > 0) {
                if (event.target.value > 3) {
                    Swal.fire({
                        title: 'Estas seguro que la comision es de un ' + event.target.value + '% ?',
                        text: "Este procentaje de comision está fuera del rango habitual!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#ff8d22',
                        cancelButtonColor: '#ff8d22',
                        fontSize: "20px",
                        fontWeigth: "600",
                        confirmButtonText: 'Sí, estoy seguro!',
                        cancelButtonText: 'No, no estoy seguro'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: 'Guardado!',
                                text: 'Se ha guardado correctamente',
                                icon: 'success',
                                confirmButtonColor: '#ff8d22'
                            }
                            )
                        } else {
                            const nuevaCompra = { ...compra, [event.target.name]: 0 };
                            setCompra(nuevaCompra);
                        }
                    })

                }
                const inputValue = parseFloat(event.target.value);
                // Limita a 6 decimales usando toFixed y luego convierte de nuevo a número
                const roundedValue = parseFloat(inputValue.toFixed(5));
                const nuevaCompra = { ...compra, [event.target.name]: roundedValue };
                setCompra(nuevaCompra);
            } else {
                const nuevaCompra = { ...compra, [event.target.name]: event.target.value };
                setCompra(nuevaCompra);
            }
        } else if (event.target.name === "Modalidad") {
            const nuevaCompra = {
                ...compra,
                [event.target.name]: event.target.value,
                Precio: 0,
                Comisiones: 0
            };

            setCompra(nuevaCompra);
        } else if (event.target.name === "PrecioRecolectado") {
            if (event.target.value > 0) {
                if ((event.target.value > 2 && compra.Modalidad !== '02') || event.target.value > 100000) {
                    Swal.fire({
                        title: 'Estas seguro que el precio recoleccion es ' + event.target.value + '€ por KG?',
                        text: "Este precio esta fuera del rango comun de precios!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#ff8d22',
                        cancelButtonColor: '#ff8d22',
                        fontSize: "20px",
                        fontWeigth: "600",
                        confirmButtonText: 'Sí, estoy seguro!',
                        cancelButtonText: 'No, no estoy seguro'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: 'Guardado!',
                                text: 'Se ha guardado correctamente',
                                icon: 'success',
                                confirmButtonColor: '#ff8d22'
                            }
                            )
                        } else {
                            const nuevaCompra = { ...compra, [event.target.name]: 0 };
                            setCompra(nuevaCompra);
                        }
                    })
                }
                const inputValue = parseFloat(event.target.value);
                // Limita a 6 decimales usando toFixed y luego convierte de nuevo a número
                const roundedValue = parseFloat(inputValue.toFixed(5));
                const nuevaCompra = { ...compra, [event.target.name]: roundedValue };
                setCompra(nuevaCompra);
            } else {
                const nuevaCompra = { ...compra, [event.target.name]: event.target.value };
                setCompra(nuevaCompra);
            }
            // if (event.target.value > 0) {
            //     // if (event.target.value > 3) {
            //     //     Swal.fire({
            //     //         title: 'Estas seguro que la comision es de un ' + event.target.value + '% ?',
            //     //         text: "Este procentaje de comision está fuera del rango habitual!",
            //     //         icon: 'warning',
            //     //         showCancelButton: true,
            //     //         confirmButtonColor: '#ff8d22',
            //     //         cancelButtonColor: '#ff8d22',
            //     //         fontSize: "20px",
            //     //         fontWeigth: "600",
            //     //         confirmButtonText: 'Sí, estoy seguro!',
            //     //         cancelButtonText: 'No, no estoy seguro'
            //     //     }).then((result) => {
            //     //         if (result.isConfirmed) {
            //     //             Swal.fire({
            //     //                 title: 'Guardado!',
            //     //                 text: 'Se ha guardado correctamente',
            //     //                 icon: 'success',
            //     //                 confirmButtonColor: '#ff8d22'
            //     //             }
            //     //             )
            //     //         } else {

            //     //             const nuevaCompra = { ...compra, [event.target.name]: 0 };
            //     //             setCompra(nuevaCompra);
            //     //         }
            //     //     })

            //     // }
            //     const inputValue = parseFloat(event.target.value);
            //     // Limita a 6 decimales usando toFixed y luego convierte de nuevo a número
            //     const roundedValue = parseFloat(inputValue.toFixed(5));
            //     const nuevaCompra = { ...compra, [event.target.name]: roundedValue };
            //     setCompra(nuevaCompra);



            // }
        // } else if (event.target.name === "Fecha") {
        //     let nuevaCompra = { ...compra, Fecha: event.target.value };
        //     nuevaCompra.Fecha1Recoleccion = event.target.value;
        //     setCompra(nuevaCompra);

         }
        else if (event.target.name === "CodigoCompra") {
            if (event.target.value <= 999999) {
                const nuevaCompra = { ...compra, [event.target.name]: event.target.value };
                setCompra(nuevaCompra);
            } else {
                const nuevaCompra = { ...compra, [event.target.name]: 999999 };
                setCompra(nuevaCompra);
            }
        }
        else {
            const nuevaCompra = { ...compra, [event.target.name]: event.target.value };
            setCompra(nuevaCompra);
        }

    };

    const handleSubmit = (buttonClicked) => {
        if (buttonClicked === 'preview') {
            guardarCompra('preview');
        } else if (buttonClicked === 'submit') {

            guardarCompra('submit');
        }
    };


    const firstData = () => {
        //representantes
        axios.get('https://' + wsUrl + '/WS/representante.php')
            .then(function (response) {
                // manejar respuesta exitosa
                setRepresentantes(response.data);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });

        //variedades
        axios.get('https://' + wsUrl + '/WS/variedades.php')
            .then(function (response) {
                setVariedades(response.data);
                const temp = [...response.data];
                const newTemp = temp.map((item) => ({
                    label: item.DesVariedad,
                    value: item.CodVariedad
                }));
                setOpVariedades(newTemp);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });

        //grupo portes
        axios.get('https://' + wsUrl + '/WS/grupoPortes.php')
            .then(function (response) {
                console.log(response.data)
                setGruposPortes(response.data);
                const temp = [...response.data];
                const newTemp = temp.map((item) => ({
                    label: item.descripcion,
                    value: item.codigoPorte
                }));
                setopGrupoPortes(newTemp);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
        //modalidades
        axios.get('https://' + wsUrl + '/WS/modalidad.php')
            .then(function (response) {
                // manejar respuesta exitosa
                setModalidad(response.data);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });

        //ejercicio
        axios.get('https://' + wsUrl + '/WS/ejercicio.php')
            .then(function (response) {
                // manejar respuesta exitosa
                const ejercicio = response.data.find((element) => element.Activo == 1);
                setEjercicios(response.data);
                setEjercicio(ejercicio);

                const nuevaCompra = { ...compra, "Ejercicio": ejercicio.Ejercicio };
                setCompra(nuevaCompra);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            })
        //proveedor
        axios.get('https://' + wsUrl + '/WS/proveedor.php?contrasena=sakmascna0o898ajhdasdj232')
            .then(function (response) {
                // manejar respuesta exitosa
                setProveedores(response.data);
                const temp = [...response.data];
                const newTemp = temp.map((item) => ({
                    label: item.Nombre + " " + item.NIF,
                    value: item.CodTercero
                }));
                setOpProveedores(newTemp);
            })
            .catch(function (error) {
                // manejar error
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            })


    }

    const guardarCompra = (option) => {

        let nuevaCompra = { default: null };

        if (proveedor) {
            if (multiProveedor) {
                nuevaCompra.Proveedor = []; // Inicializa como un array vacío
                arrProveeCompra.map((proveedor, index) => {
                    var proveedorData = proveedores.find((prov) => proveedor.value === prov.CodTercero);
                    proveedorData.porcentaje = proveedor.porcentaje;
                    let tmp = [...nuevaCompra.Proveedor];
                    tmp.push(proveedorData);
                    nuevaCompra = { ...compra, Proveedor: tmp };
                });
            } else {
                nuevaCompra.Proveedor = []; // Inicializa como un array vacío
                var proveedorData = proveedores.find((prov) => prov.CodTercero === proveedor.value);
                proveedorData.porcentaje = 100;
                let tmp = [...nuevaCompra.Proveedor];
                tmp.push(proveedorData);
                nuevaCompra = { ...compra, Proveedor: tmp };
            }
        }
        if (variedad) {
            nuevaCompra.Variedad = variedad.value;
        }
        if (grupoPorte) {
            nuevaCompra.grupoPortes = grupoPorte.value;
            console.log(nuevaCompra)
        }

        const isNullInObject = Object.keys(nuevaCompra).every(key => {
            // Hacer una excepción para Observaciones
            if (sinComision) {
                if (key === 'Comisiones') return true;
            }
            if (key === 'Observaciones' || key === 'Anticipo' || key === 'ivaPrecio' || key === 'CodigoCompra' || 'Fecha1Recoleccion') {
                return true;
            }
            if (compra.Modalidad === "01") {
                if (key === 'Precio') return true;
            }
            if (compra.Modalidad !== "04") {
                if (key === 'PrecioRecolectado') return true;
            }

            return nuevaCompra[key] !== null && nuevaCompra[key] !== '' && nuevaCompra[key] != 0;
        });
        const multiproveedorConfirmed = multiProveedor ? dissSldiers : true;
        if (!isNullInObject || !multiproveedorConfirmed) {
            console.log(compra)

            Swal.fire({
                icon: 'error',
                title: 'Revisa que los campos esten correctamente',
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            console.log(nuevaCompra)
            axios.post('https://' + wsUrl + '/WS/comprasPost.php', nuevaCompra)
                .then(function (response) {
                    if (response.data.success) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Compra Creada correctamente',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        {
                            option === 'submit' ? (
                                Navigate("/pdf-preview/" + response.data.idCompra)
                            ) : (
                                Navigate("/")
                            )
                        }
                    } else {
                        console.log(response.data)

                        Swal.fire({
                            icon: 'error',
                            title: 'Error en la solicitud. \nRevisa que los campos esten correctamente',
                            showConfirmButton: false,
                            timer: 1500
                        })

                    }

                })
                .catch(function (error) {
                    console.error('Error al enviar la solicitud:', error);
                });
        }
    }

    //manejar el array de varios proveedores
    const añadirArrProveedores = (id) => {
        if (id) {
            const tmp = arrProveeCompra.filter(prov => prov.value !== id);
            setArrProveeCompra(tmp);
        } else {
            if (arrProveeCompra.find(prov => prov.value === tmpProve.value)) {
                Swal.fire({
                    icon: 'error',
                    title: 'No puedes añadir un Proveedor que ya participa en esta compra',
                    showConfirmButton: false,
                    timer: 2500
                })
            } else if (tmpProve) {
                var tmpTmpProve = tmpProve;
                tmpTmpProve = { ...tmpTmpProve, porcentaje: 0 };
                var tmp = [...arrProveeCompra, tmpTmpProve];
                setArrProveeCompra(tmp);
            }

        }

    }

    const añadirProvPrincipalArrProv = () => {
        var tmpTmpProve = proveedor;
        tmpTmpProve = { ...tmpTmpProve, porcentaje: 0 };
        var tmp = [...arrProveeCompra, tmpTmpProve];
        setArrProveeCompra(tmp);
    }

    const prepararArrPRoveeMultiple = () => {
        let totalPorcentajes = 0;
        arrProveeCompra.map((proveedorAux, index) => {
            totalPorcentajes += proveedorAux.porcentaje;
        })

        const badValuePorcen = arrProveeCompra.find(proveedorAux => proveedorAux.porcentaje < 1);
        if (badValuePorcen) {
            Swal.fire({
                icon: 'error',
                title: 'No puedes asignar menos de 1% a un proveedor',
                showConfirmButton: false,
                timer: 2500
            });
        } else {
            if (totalPorcentajes !== 100) {
                Swal.fire({
                    icon: 'error',
                    title: 'La suma de los porcentajes debe dar 100% y actualmente es ' + totalPorcentajes + '%',
                    showConfirmButton: false,
                    timer: 2500
                })
            } else {
                setDissSliders(true);
                Swal.fire({
                    icon: 'success',
                    title: 'Los porcentajes se han guardado correctamente',
                    showConfirmButton: false,
                    timer: 2500
                })
            }
        }
    }
    return (
        <div>
            <Header />
            <div className='flex-left'>
                <div className='return-button' onClick={() => Navigate("/")}>
                    <KeyboardReturnIcon />
                    &nbsp;Cancelar
                </div>
            </div>
            <div className='flex-container'>
                <div className="card-view">
                    <div className='card-view-title'>
                        Crear Compra
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='tip-text'>
                            {(compra.Modalidad) ? "" : "Porfavor Selecciona la Modalidad de la compra"}
                        </div>
                        <div className='card-view-body'>
                            {(proveForm !== true) && (
                                <div className='card-view-element'>
                                    Modalidad:&nbsp;
                                    <Select
                                        className={compra.Modalidad ? "text-field" : 'text-field-empty'}
                                        value={compra.Modalidad || ''}
                                        name={"Modalidad"}
                                        onChange={handleChange}
                                        defaultValue=""

                                    >
                                        {modalidad.map((mod, key) => (
                                            <MenuItem
                                                key={mod.CodModalidad}
                                                value={mod.CodModalidad}
                                                onChange={handleChange}
                                            >
                                                {mod.DesModalidad}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            )}
                            {(proveForm !== true && (compra.Modalidad !== null)) && (
                                <>
                                    <div className='card-view-element'>
                                        Campaña:&nbsp;
                                        <Select
                                            className={compra.Ejercicio ? "text-field" : 'text-field-empty'}
                                            value={compra.Ejercicio || ''}
                                            name={"Ejercicio"}
                                            onChange={handleChange}
                                            defaultValue=""
                                        // onChange={handleChange}
                                        >
                                            {ejercicios.map((ejercicio, key) => (
                                                <MenuItem
                                                    key={ejercicio.Ejercicio}
                                                    value={ejercicio.Ejercicio}
                                                    onChange={handleChange}
                                                >
                                                    {ejercicio.Descripcion}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className='card-view-element'>
                                        Código compra Papel:&nbsp;
                                        <TextField
                                            required
                                            id="CodigoCompra"
                                            className='text-field'
                                            name={"CodigoCompra"}
                                            value={compra.CodigoCompra || ''}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className='card-view-element'>
                                        Fecha:&nbsp;&nbsp;&nbsp;
                                        <div className="dateInputContainer">
                                            <input
                                                type="date"
                                                id="dateInput"
                                                name="Fecha"
                                                className={compra.Fecha ? "inputDateInput" : 'inputDateInput-empty'}
                                                required
                                                value={compra.Fecha ? compra.Fecha : new Date().toISOString().split('T')[0]} // Se establece la fecha de hoy si compra.Fecha es null
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='card-view-element'>
                                        {multiProveedor ? 'Proveedor principal: ' : 'Proveedor: '}
                                        <div className='add-button' onClick={() => setProveFrom(true)}>
                                            <AddCircleOutlineIcon fontSize="large" />
                                        </div>
                                        <Autocomplete
                                            className={proveedor ? "inputDateInput" : 'inputDateInput-empty'}
                                            disablePortal
                                            id="combo-box-demo"
                                            name="Proveedor"
                                            disabled={multiProveedor ? true : false}
                                            options={opProveedores || ''}
                                            sx={{ width: 300 }}
                                            value={proveedor}
                                            onChange={(event, newValue) => {
                                                setProveedor(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Obligatorio*" />}
                                        />
                                        {(multiProveedor !== true) && (
                                            <div className={proveedor ? 'add-proveedor-button' : 'diss-proveedor-button'} onClick={proveedor ? () => { setMultiProveedor(true); añadirProvPrincipalArrProv() } : null}>
                                                Añadir más proveedores a esta compra
                                            </div>
                                        )}
                                    </div>
                                    {(multiProveedor === true) && (

                                        <div className='card-view-form'>
                                            {(dissSldiers !== true) && (
                                                <>
                                                    Busca y añade los demás proveedores que participen en esta compra.
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={opProveedores || ""}
                                                        sx={{ width: 300 }}
                                                        onChange={(event, newValue) => {
                                                            setTmpProve(newValue);
                                                        }}
                                                        renderInput={(arrProveeCompra) => <TextField {...arrProveeCompra} />}
                                                    />
                                                    <div className={tmpProve ? 'add-proveedor-button' : 'diss-proveedor-button'} onClick={tmpProve ? () => añadirArrProveedores() : null}>
                                                        Añadir
                                                    </div>
                                                    <div className='add-proveedor-button' onClick={() => { setMultiProveedor(false); setArrProveeCompra([]); setTmpProve("") }}>
                                                        Cancelar
                                                    </div>
                                                </>
                                            )}
                                            Proveedores de la compra:
                                            {arrProveeCompra.length >= 1 && (
                                                <div>
                                                    {arrProveeCompra.map((prov, index) => (
                                                        <>
                                                            <ProveedorCard key={prov.value}
                                                                setArrProveeCompra={setArrProveeCompra}
                                                                arrProveeCompra={arrProveeCompra}
                                                                tmpProve={tmpProve}
                                                                prov={prov}
                                                                idProveedorPrincipal={proveedor.value}
                                                                dissSldiers={dissSldiers}
                                                            />
                                                        </>

                                                    ))}
                                                </div>
                                            )}
                                            {(dissSldiers !== true) && (
                                                <div className='firmar-button' onClick={() => prepararArrPRoveeMultiple()}>
                                                    Confirmar porcentajes de la compra
                                                </div>
                                            )}
                                            {(dissSldiers === true) && (
                                                <div className='firmar-button' onClick={() => setDissSliders(false)}>
                                                    Editar porcentajes
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                            {(proveForm === true) && (
                                <div className='card-view-form'>
                                    Dar de alta un Proveedor:&nbsp;
                                    <AltaProveedor
                                        setProveFrom={setProveFrom}
                                        setProveedores={setProveedores}
                                        proveedores={proveedores}
                                        opProveedores={opProveedores}
                                        setOpProveedores={setOpProveedores}
                                    />
                                </div>
                            )}
                            {(proveForm !== true) && (
                                <>

                                    {((compra.Modalidad !== null)) && (
                                        <>
                                            <div className='card-view-element'>
                                                {/* partida se guarda como referencia por sage */}
                                                Partida:&nbsp;
                                                <TextField
                                                    className={compra.Referencia ? "text-field" : 'text-field-empty'}
                                                    required
                                                    id="codigo"
                                                    label="Obligatorio"
                                                    name={"Referencia"}
                                                    value={compra.Referencia || ''}
                                                    onChange={handleChange}
                                                />
                                                Grupo Portes:&nbsp;
                                                <Autocomplete
                                                    className={grupoPorte ? "inputDateInput" : 'inputDateInput-empty'}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    name={"grupoPortes"}
                                                    options={opGrupoPortes}
                                                    sx={{ width: 300 }}
                                                    value={grupoPorte || ""}
                                                    onChange={(event, newValue) => {
                                                        setGrupoPorte(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Obligatorio*" />}
                                                />
                                            </div>
                                            <div className='card-view-element'>
                                                Representante:&nbsp;
                                                <Select
                                                    className={compra.Representante ? "text-field" : 'text-field-empty'}
                                                    value={compra.Representante || ''}
                                                    name={"Representante"}
                                                    onChange={handleChange}
                                                    defaultValue=""
                                                // onChange={handleChange}
                                                >
                                                    {representantes.map((representante, key) => (
                                                        <MenuItem
                                                            key={representante.CodReferencia}
                                                            value={representante.CodReferencia}
                                                            onChange={handleChange}
                                                        >
                                                            {representante.DesRepresentante}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                            * <div className='card-view-element'>
                                                Fecha Límite:&nbsp;
                                                <div className="dateInputContainer">
                                                    <input
                                                        type="date"
                                                        id="dateInput"
                                                        name="Fecha1Recoleccion"
                                                        className= "inputDateInput"
                                                        value={compra.Fecha1Recoleccion} onChange={handleChange} />
                                                </div>
                                            </div> 
                                            <div className='card-view-element'>
                                                Variedad:&nbsp;
                                                <Autocomplete
                                                    className={variedad ? "inputDateInput" : 'inputDateInput-empty'}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    name="Variedad"
                                                    options={opVariedades}
                                                    sx={{ width: 300 }}
                                                    value={variedad}
                                                    onChange={(event, newValue) => {
                                                        setVariedad(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Obligatorio*" />}
                                                />
                                            </div>

                                            <div className='card-view-element'>
                                                Cantidad:&nbsp;
                                                <TextField
                                                    className={compra.Cantidad ? "text-field" : 'text-field-empty'}
                                                    required
                                                    id="codigo"
                                                    label="Obligatorio"
                                                    type='integer'
                                                    name={"Cantidad"}
                                                    value={compra.Cantidad || ''}
                                                    onChange={handleChange}
                                                />
                                                KG
                                            </div>
                                            {(compra.Modalidad !== '01') && (
                                                <div className='card-view-element'>
                                                    IVA&nbsp;
                                                    <Checkbox
                                                        value="checkIva"

                                                        inputProps={{
                                                            'aria-label': 'Checkbox A',
                                                        }}
                                                        checked={!compra.ivaPrecio}

                                                        onChange={() => {
                                                            if (compra.ivaPrecio) {
                                                                setCompra(prevCompra => ({ ...prevCompra, ivaPrecio: false }));
                                                            } else {
                                                                setCompra(prevCompra => ({ ...prevCompra, ivaPrecio: true }));
                                                            }
                                                        }}
                                                    />
                                                    {((compra.Modalidad === '04') ? 'Precio Fruta' : (compra.Modalidad !== '02') ? 'Precio por KG ' : 'Precio total ') + (compra.ivaPrecio ? 'sin IVA' : ' IVA inlcuido')}
                                                    &nbsp;
                                                    <TextField
                                                        required
                                                        id="codigo"
                                                        label="Obligatorio"
                                                        value={compra.Precio || ''}
                                                        className={compra.Precio ? "text-field" : 'text-field-empty'}
                                                        type="number"
                                                        step="0.00001" // Permitir hasta 6 decimales
                                                        name="Precio"
                                                        onChange={handleChange}
                                                    />
                                                    €
                                                </div>
                                            )}
                                            {(compra.Modalidad === '04') && (
                                                <div className='card-view-element'>
                                                    {(compra.Modalidad == '04') ? 'Precio Recoleccion' : 'Precio por kg RECOLECTADO'}
                                                    &nbsp;
                                                    <TextField
                                                        required
                                                        id="codigo3"
                                                        label="Obligatorio"
                                                        value={compra.PrecioRecolectado || ''}
                                                        className={compra.PrecioRecolectado ? "text-field" : 'text-field-empty'}
                                                        type="number"
                                                        step="0.00001" // Permitir hasta 6 decimales
                                                        name={"PrecioRecolectado"}
                                                        onChange={handleChange}
                                                    />
                                                    €
                                                </div>
                                            )}
                                            <div className='card-view-element '>
                                                <div>
                                                    Sin Comision&nbsp;
                                                    <Checkbox
                                                        value="checkedA"
                                                        inputProps={{
                                                            'aria-label': 'Checkbox A',
                                                        }}
                                                        onChange={sinComision ? () => setSinComision(false) : () => setSinComision(true)}
                                                    /></div>
                                                Comisión:&nbsp;
                                                <TextField
                                                    required
                                                    id="codigosd"
                                                    label="Obligatorio"
                                                    type="number"
                                                    step="0.000001"
                                                    disabled={sinComision ? (true) : false}
                                                    className={sinComision ? 0 : compra.Comisiones ? "text-field" : 'text-field-empty'}
                                                    name={"Comisiones"}
                                                    value={sinComision ? 0 : (compra.Comisiones || '')}
                                                    onChange={handleChange}
                                                />
                                                %
                                            </div>
                                            <div className='card-view-element '>
                                                Observaciones:&nbsp;
                                                <TextField
                                                    required
                                                    id="codigo"
                                                    label="No Obligatorio"
                                                    className={"text-field"}
                                                    name={"Observaciones"}
                                                    multiline
                                                    rows={4}
                                                    value={compra.Observaciones || ''}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='card-view-element '>
                                                <div>A.N:&nbsp;
                                                    <Checkbox
                                                        value="checkedA"
                                                        inputProps={{
                                                            'aria-label': 'Checkbox A',
                                                        }}
                                                        onChange={anticipado ? () => setAnticipado(false) : () => setAnticipado(true)}
                                                    /></div>
                                                <div>{(anticipado === true) && (
                                                    <div >
                                                        <TextField
                                                            required
                                                            id="codigo"
                                                            value={compra.Anticipo || ''}
                                                            className="text-field"
                                                            type="number"
                                                            step="0.00001" // Permitir hasta 6 decimales
                                                            name="Anticipo"
                                                            onChange={handleChange}
                                                        />
                                                        €
                                                    </div>
                                                )}
                                                </div>
                                            </div>
                                        </>
                                    )}


                                </>
                            )}
                        </div>
                        {(compra.Modalidad !== null) && (
                            <div className='flex-container bottom-space'>
                                <Grid container spacing={6} justifyContent="center" >
                                    <Grid item xs={10} sm={6} md={3}>
                                        {(proveForm !== true) && (
                                            <div onClick={() => handleSubmit('preview')}>
                                                <div className='finalizar-compra-button' type="submit">
                                                    Guardar y ir a la lista
                                                </div>
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={10} sm={6} md={3}>
                                        {(proveForm !== true) && (
                                            <div onClick={() => handleSubmit('submit')}>
                                                <div className='finalizar-compra-button' type="submit">
                                                    Guardar y previsualizar
                                                </div>
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                    </form>
                </div>
            </div>
        </div>
    )
}
