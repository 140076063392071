import { Dialog, Fab, Grid } from '@mui/material'
import React, { useState } from 'react'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { CompraInfo } from './CompraInfo';
export const CompraEnviadaCard = ({ compra, bgColor }) => {

    const [open, setOpen] = useState(false);

    return (
        <Grid container xs={12} justifyContent={'space-between'} padding={"1em"} direction={'row'} sx={{ backgroundColor: bgColor }}>
            <Grid item>
                Fecha Envio {compra.date}
            </Grid>
            <Grid item>
                Titulo: {compra.title}
            </Grid>
            <Grid item>
                Estado: {compra.status_name}
            </Grid>
            <Grid item>
                <strong>
                    Fecha Expiracion:&nbsp;
                </strong>
                {compra.expiration_date ? compra.expiration_date : "Sin expiracion"}
            </Grid>

            <Grid item>
                Nombre Archivo: {compra.file_name}
            </Grid>
            <Grid item>
                Fecha Envio{compra.date}
            </Grid>
            <Grid item xs={12}>
                <Fab size='small' onClick={() => setOpen(!open)}>
                    <PriorityHighIcon ></PriorityHighIcon>
                </Fab>
            </Grid>
            {open && (
                <CompraInfo open={open} setOpen={setOpen} id={compra.id} status_code={compra.status_code}></CompraInfo>
            )}
        </Grid>
    )
}
