import React, { useEffect, useState } from 'react'
import { getListaDocumentos } from '../services/apis';

export const DocumentosFirmas = () => {
	const [pdfUrl, setPdfUrl] = useState(null);

	const cargarOpciones = async () => {
		try {
			// 1. Llamar a la API
			const response = await getListaDocumentos();

			// Supongamos que la respuesta trae algo como response.data.original = "JVBERi0xLjQKJeLjz9MKN..."
			//   const base64 = response.data.original; 
			const base64 = response.data.proof;

			// 2. Decodificar Base64 -> cadena binaria
			const binaryString = atob(base64);

			// 3. Crear un array de bytes (Uint8Array) a partir de esa cadena binaria
			const len = binaryString.length;
			const bytes = new Uint8Array(len);
			for (let i = 0; i < len; i++) {
				bytes[i] = binaryString.charCodeAt(i);
			}

			// 4. Crear un Blob de tipo PDF
			const blob = new Blob([bytes], { type: 'application/pdf' });

			// 5. Crear una URL temporal para ese Blob
			const url = URL.createObjectURL(blob);

			// Guardamos la URL en el estado para usarla en el render
			setPdfUrl(url);

		} catch (error) {
			console.error("Error al cargar opciones del menú:", error);
		}
	};

	useEffect(() => {
		cargarOpciones();
	}, []);

	return (
		<div>
			<h2>Documentos Firmas</h2>

			{/* 6. Si tenemos la url, mostramos un iframe para ver el PDF */}
			{pdfUrl && (
				<iframe
					src={pdfUrl}
					width="600"
					height="800"
					title="PDF"
					style={{ border: 'none' }}
				></iframe>
			)}

			{/* Opcional: link de descarga */}
			{pdfUrl && (
				<p>
					<a href={pdfUrl} download="documento.pdf">
						Descargar PDF
					</a>
				</p>
			)}
		</div>
	)
}
