import axios from "axios";
import { getCookie } from "../utils/cookies";


const baseUrl = 'https://compras.clasol.com/WS/'
//opciones menu
export const getopcinesMenu = async () => {
    try {
        const response = await axios.get(baseUrl + 'opcionesmenu.php?', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log(response)
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getopcinesCompras = async () => {
    try {
        const response = await axios.get(baseUrl + 'opcionescompras.php?', {
            params: { token: getCookie('PHPSESSID') },
        });
        console.log("ss", response)

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


////////////////// APIS FIRMAS DOCUTEN////////////////////////////////////


export const getListaDocumentos = async () => {
    try {
        const response = await axios.get(baseUrl + 'listadoDocumentos.php?', {
            params: { token: getCookie('PHPSESSID') },
        });
        console.log("ss", response)

        if (response.data) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}

export const getDocumento = async (id, status_code) => {
    try {
        const response = await axios.get(baseUrl + 'documentoDocuten.php', {
            params: {
                token: getCookie('PHPSESSID'),
                id: id,
                status_code: status_code
            },
        });
        console.log("ss", response)

        if (response.data) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}

// export const getDocumento = async () => {
//     try {
//         const response = await axios.get(baseUrl + 'documentDocuten.php?', {
//             params: { token: getCookie('PHPSESSID') },
//         });
//         console.log("ss", response.data)

//         if (response.data) {
//             // Procesar la respuesta y actualizar las opciones si es necesario
//             return response.data;
//         } else {
//             console.log('No se pudo verificar el token');
//             return null;
//         }
//     } catch (error) {
//         console.error('Error al verificar el token:', error);
//         return null;
//     }
// }
/////////////////////////////////////////////////////////////////////////
///////////////// APIS DIRMAS AICA ///////////////////////////////////////
//////////////////////////////////////////////////////////////////////////